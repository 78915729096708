body {
  margin: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #313131;

}

p {
  color: white;
}

p.title{
  color: black;
  white-space: pre-line;
}

ol, ul {
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 30px;
}

li {
    float: left;
    padding-top: 0px;
    padding-left: 0px;
    color: #646464;
}

h2 {
  color: #CCCCCC;
  text-shadow: 0 0 2px #0050B2;
  box-shadow: 0px 1px 0px 0px white;
  border-bottom: 3px solid #605B60;
  border-radius: 5%;
}

h3 {
  color: #79C7EC;
  text-shadow: 0 0 2px #0050B2;
}

h4 {
  color: #94CEC7;
  font-size: 20px;
  text-shadow: 1px -1px 2px #627C7A;
}

p.desc {
  color: white;
}

.popup {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;

  top: 25%;

  margin: auto;
}

.row {
  margin-top: 5px;
}

.lng {
  font-size: 12px;
}
#header-content {

	-webkit-background-size: 100% auto;
	background-size: 100% auto;
	background-repeat: no-repeat;
  margin: 15px;
}

.info-box {
  background: #282C34;
  margin-top: 15px;
}

.table-sm {
  border-radius: 5px;
  width: 50%;
  margin: 0px auto;
  float: none;
}

.tabletitle {
  margin-top: 15px;
  margin-bottom: 15px;
}

.tabletitle_m {
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-x: scroll;
}

.Collapsible {
  width: 100%;
}

.Collapsible__trigger {
  display: block;
  color: #94CEC7;
  text-shadow: 1px -1px 2px #627C7A;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #282C34;
  color: white;
}

.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg); }
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey; }

.dotoff {
  height: 25px;
  width: 30px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #313031;
  box-shadow:0px 0px 2px 2px grey;
}

.doton {
  height: 25px;
  width: 30px;
  display: table-cell;
  text-align: center;
  color: #79C7EC;
  text-shadow: 1px 1px #0050B2;
  vertical-align: middle;
  border-radius: 50%;
  background: #313031;
  box-shadow:0px 0px 2px 2px #0099FF;
}

.dotspoff {
  height: 25px;
  width: 30px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #313031;
  box-shadow:0px 0px 2px 2px #4C4D4C;
}

.dotspon {
  height: 25px;
  width: 30px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;
  text-shadow: 1px 1px #0099FF;
  border-radius: 50%;
  background: #313031;
  box-shadow:0px 0px 2px 2px #79C7EC;
}

.logo-row {

}

.tbody {
  width:100%;
  table-layout: auto;
  border-radius: 20%;
  background: white;
}


.Conceptual-on {
  background:#A52175;
  border-style: solid;
  border-color: #A52175;
}

.Fundamental-on {
  background:#FF6A00;
  border-style: solid;
  border-color: #FF6A00;
}

.Offensive-on {
  background:#D02519;
  border-style: solid;
  border-color: #D02519;
}

.Protective-on {
  background:#0C42C2;
  border-style: solid;
  border-color: #0C42C2;
}

.Curative-on {
  background:#0EBC00;
  border-style: solid;
  border-color: #0EBC00;
}

.Tactical-on {
  background:#DDB90E;
  border-style: solid;
  border-color: #DDB90E;
}

.Inimical-on {
  background:#8B3EAF;
  border-style: solid;
  border-color: #8B3EAF;
}

.Mitigative-on {
  background:#1E9EB0;
  border-style: solid;
  border-color: #1E9EB0;
}

.Obscure-on {
  background:#F08080;
  border-style: solid;
  border-color: #F08080;
}

.Conceptual-off {
  background-image: linear-gradient(to right, #A52175, gray, gray);

}

.Fundamental-off {
  background-image: linear-gradient(to right, #FF6A00, gray, gray);


}

.Offensive-off {
  background-image: linear-gradient(to right, #D02519, gray, gray);


}

.Protective-off {
  background-image: linear-gradient(to right, #0C42C2, gray, gray);

}

.Curative-off {
  background-image: linear-gradient(to right, #0EBC00, gray, gray);

}

.Tactical-off {
  background-image: linear-gradient(to right, #DDB90E, gray, gray);

}

.Inimical-off {
  background-image: linear-gradient(to right, #8B3EAF, gray, gray);

}

.Mitigative-off {
  background-image: linear-gradient(to right, #1E9EB0, gray, gray);

}

.Obscure-off {
  background-image: linear-gradient(to right, #F08080, gray, gray);

}


.nums {

}

.status {
  border: 100px solid black;

}



.square {
  background: #4A494A;
  float: left;
  font-size: 24px;
  font-weight: bold;
  height: 50px;
  border-radius: 25%;
  padding: 0px;
  text-align: center;
  width: 50px;
}


.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}


.me {
  background: #908478;
  border-radius: 2%;
  box-shadow:0px 0px 3px 3px #94865A;
}

.mo {
  background: #BBBBB1;
  border-radius: 2%;
  box-shadow:0px 0px 3px 3px #94865A;
}

.info-box {
  margin-left: 0px;
  background: white;
  border-radius: 2%;
  box-shadow:0px 0px 3px 3px #94865A;
}

.footer {
  position:relative;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: black;
  z-index: -1;
}

.spinner {

  float: right;
}

.spinner input {
  text-align: right;
  height: 34px;
}

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}

.input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 4px;
}

.input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px;
}

.input-group-btn-vertical i {
  position: absolute;
  top: 0;
  left: 4px;
}

span {
  font-size: 14px;
}


table {
  border-collapse:separate;
  border-spacing:0 2px;
}

.icon {
  width: 20px;
  height: 20px;
}
